* {
  user-select: none;
}

html, body {
  background: black;
  width: 100vw;
  height: 100%; /* Using 100% because 100vh on mobiles will not include the navbar*/
}

#root {
  height:100%;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas {
  width: 100%;
  height: 100%;
}

.navBar {
  text-align: left;
  color: white;
}

.MuiPaper-root {
  border-bottom: 1px #4d4d4d solid;
}

.white {
  color: white;
}

.buttons-container {
  position: absolute;
  z-index: 999;
  //height: 100px; // TODO MAKE THIS DYNAMIC, SOME WEIRD PADDING OR SOMETHING OTHERWISE
  bottom: 0px;
  right: 0;
  padding: 5px;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}

/*ipad mini*/
@media screen and (min-width: 768px) {
  .buttons-container {
    bottom: 0;
  }
}

.buttons-container--left {
  left:0;
  right: inherit;
}

.buttons-container--left-helper {
  /*left:5vw;*/
  right: inherit;
}

.buttons-container--mid-right {
  display: flex;
  flex-direction: column;
  right: 15px;
  top: 70%;
  /*right: inherit;*/

  @media screen and (min-width: 768px) {
    right: 8px;
  }
}

.helperText {
  color: white;
  display: block;
  margin: 5px 5px;
  text-decoration: underline;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.pointer {
  cursor: pointer;
}

.slide-out-gallery {
  position:absolute;
  right: 0;
  /*width: 100px;*/
  height: 100vh;
  z-index:10;
  overflow:scroll;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(100%); }
}

.slide-out-gallery-container {
  margin-top: 65px; /* height of nav bar */
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
}

.slide-out-gallery-image {
  width: 300px;
  height: auto;
}

.closeModalButton {
  cursor: pointer;
  position: absolute !important;
  right: 5px !important;
  top:5px !important;
}

.home-screen-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.home-screen-cover {
  display: flex;
  position: absolute;
  width:100%;
  height:100%;
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
  opacity: 0.9;
  justify-content: center;
  align-items: center;
}

.home-screen-button {
  color: black !important;
  border-color: white !important;
  background-color: white !important;
  font-weight: bold !important;
}

.drawer-link {
  color: #e3dbce;
  text-decoration: none !important;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


.main-link {
  color: white;
  text-decoration: none !important;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.main-title {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  //color: #615438;
  //color: "#e3dbce",
}

.main-title-navbar {
  padding: 8px;
  padding-left: 14px;
}

.info-map {
  min-height: 40vh;
}

.drawer {
  background-color: #615438;
  color: white;

  //#e3dbce
}

.light-divider {
  background-color: #383838 !important;
}


/*menu*/
.react-pdf__Document {
  display: flex;
  justify-content: center;
}

.react-pdf__Page__canvas {
  max-width:100%;
  max-height: 100%;
  height:100% !important;
  width: 100% !important;
}

.react-pdf__Page__textContent {
  max-width:100%;
  max-height: 100%;
  height:100% !important;
  width: 100% !important;
}

.room-paginator {
  color: white;

  button {
    color: white;
  }
}

.centre-overlay {
  font-size: 50px;
  line-height: 90px;
  z-index:9999;
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    font-size: 100px;
    line-height: 160px;
  }
}

.first-person-selector {
  //style={{position: "absolute", bottom: "5vh", left: "45%", zIndex: "99"}}
  position: absolute;
  z-index: 99;
  bottom: 3%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}


.infoModal__button-container {
  display: flex;
  justify-content: flex-end;

  &-end {
    display: flex;
    justify-content: end !important;
  }
  &-between {
    display: flex;
    justify-content: space-between !important;
  }
}


.movementControls {
  * {
    font-size: 12px !important;
  }
  /*--breakpointMobile: 360px; can't use in media queries */
  /*--breakpointTablet: 768px; can't use in media queries*/
  @media screen and (min-width: 1080px) {
    display: none;
  }

  position: fixed;
  bottom: 0;
  //top: 50%;
  //left:50%;
  z-index: 98;
  width: 100%;
  height: 35vh;

  padding: 5px;
  font-size: 30px !important;
  //pointer-events: none;
  user-select: none;

  &__container {
    position: relative;
    height: 100%;
  }

  .rightPan {
    grid-area: 2 / 3 / 3 / 4;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;
  }
  .leftPan {
    grid-area: 2 / 1 / 3 / 2;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;
  }

  &__movementContainer {
    position: absolute;
    top: 0;
    left: 10%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  &__panContainer {
    position: absolute;
    top: 0;
    right: 10%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30%;
    left: calc(50% - 62px);
    //top: 0;
    //right: 10%;
    //
    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: repeat(3, 1fr);
    //grid-column-gap: 0px;
    //grid-row-gap: 0px;
  }

  &__buttonsContainerKeys {
    display: flex;
    flex-direction: row;
  }

  .pPress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-right: 5px;
  }

  .ePress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-right: 5px;
  }
  .iPress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-right: 5px;
  }

  .spacePress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-top: 5px;
    margin-right: 5px;
  }

  .rightMove {

    grid-area: 2 / 3 / 3 / 4;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
  .forwardMove {
    grid-area: 1 / 2 / 2 / 3;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
  .leftMove {
    grid-area: 2 / 1 / 3 / 2;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
  .backwardMove {
    grid-area: 3 / 2 / 4 / 3;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
}


/**
Slider
//todo replace slider (INCLUDING REMOVING THE LINK FROM THE INDEX.HTML
 */
.slick-track {
  width: 100% !important;
}
.slick-arrow .slick-prev {
  display: none !important
}

.slick-arrow .slick-next {
  display: none !important;
}
.slick-dots {
  display: none !important;
}

.slick-slider {
  //overflow: hidden;
  button {
    display: none !important;
  }
}

.room-selector {
  top: 106px;
  left: 170px;
  min-width: 320px;

  border-bottom: 1px solid white;

  @media screen and (min-width: 768px) {
    top: 120px;
    left: 180px;
  }

  & .MuiFormControl-root {
    display: flex;
    flex-direction: row;
    max-width: 250px;
    padding-bottom:8px;

  }

  & .MuiOutlinedInput-root {
    max-width: 250px;
    color: white;
  }

  & label {
    color: white;
  }

  position: fixed;
  padding: 5px 5px 0 5px;
  z-index: 99;


  /* bring your own prefixes */
  transform: translate(-50%, -50%);

  //background-color: white;

  display: flex;
  flex-direction: row;
}


.asset-adder {
  top: 206px;
  left: 170px;
  min-width: 320px;

  border-bottom: 1px solid white;

  @media screen and (min-width: 768px) {
    top: 220px;
    left: 180px;
  }

  & .MuiFormControl-root {
    display: flex;
    flex-direction: row;
    max-width: 250px;
    padding-bottom:8px;

  }

  & .MuiOutlinedInput-root {
    max-width: 250px;
    color: white;
  }

  & label {
    color: white;
  }

  position: fixed;
  padding: 5px 5px 0 5px;
  z-index: 99;


  /* bring your own prefixes */
  transform: translate(-50%, -50%);

  //background-color: white;

  display: flex;
  flex-direction: row;
}

.hq-selector {
  &--on {
    color: white;
  }
  &--off {
    color: white;
  }
  & svg {
    font-size: 60px;
  }
}

.counter {

  top: 134px;

  display: flex;
  flex-direction: column;

  position: absolute;
  z-index: 99;

  right: 0px;
  padding: 12px 0 !important;

  @media screen and (min-width: 768px) {
    top: 60px;
    padding: 16px 6px !important;
  }

  &__count {
    color: white;
  }
  &__counter {
    display: flex;
    flex-direction: row;
  }
}


.zoom-slider {
  position: absolute !important;
  right: 20px;
  top: 40%;
  z-index: 99;
  height: 200px !important;
}

.pan-slider {
  position: absolute !important;
  bottom: 50px;
  width: 200px !important;
  z-index: 99;
}

.blueprint {
  width: 100%;
}

.button {
  cursor: pointer;

  &--light {
    color: lightgrey;
  }
}

.warning-modal-hq {

  & .hq-selector {
    display: flex;
    flex-direction: row;
  }
}


.site-button {
  font-size: 40px !important;
}


.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.hidden {
  display: none;
}
